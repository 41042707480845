<style lang="scss">

.order-wrap {
  padding: 30px;
  margin-top: 60px;
  margin-bottom: 30px;

  h2 {
    font-size: 36px;
    line-height: 1;
    //margin: 0;
    font-weight: 400;
  }
}

.order-item {
  background: #f2f2f2;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 14px;

  > div {padding: 10px 16px;}

  .order-title {width: 60%; font-size: 1.25em; line-height: 24px;}

  .order-price { width: 20%; line-height: 24px;}

  .order-date {width: 20%; text-align: right; line-height: 24px;}

  .icon-price {
    transform: rotate(45deg);
  }

  .unit-tag {
    font-size: 12px;
  }
}

.pagination {text-align: center; margin: 30px auto;}

@media only screen and (max-width: 641px) {
  .order-wrap {
    margin-top: 10px;
  }
  .order-item {
    .order-title {width: 100%}

    .order-price { width: 40%}

    .order-date {width: 60%; text-align: right;}
  }

}

</style>


<template>
  <div class="container">

    <div class="order-wrap">
      <h2>已购买清单</h2>
      <div class="order-item" v-for="item in items" :key="item.id">
        <div class="order-title">
          <router-link :to=" '/members/'+ item.article_id">
            {{ item.article }}
          </router-link>
        </div>
        <div class="order-price"><i class="el-icon-discount icon-price"></i> {{ item.price }}<span class="unit-tag">元</span></div>
        <div class="order-date">{{ item.date }}</div>
      </div>

      <el-pagination class="pagination"
                     background
                     layout="prev, pager, next"
                     :current-page="curPage"
                     :page-count="totalPage"
                     :hide-on-single-page="true"
                     @current-change="handleCurrentChange">
      </el-pagination>
      <el-empty v-if="items.length===0">
        <router-link :to="'/members/list'">
          <el-button type="primary">查看文章列表</el-button>
        </router-link>
      </el-empty>

      <br><br>
    </div>
  </div>
</template>
<script>
// import Masonry from "../components/masonryAuth";

export default {
  name: "List",
  components: {
    // Masonry
  },
  data() {
    return {
      items: [{
        date: '2016-05-02',
        price: '王小虎',
        article: '上海市普陀区金沙江路 1518 弄',
        article_id: 0
      }],
      totalPage: 1,
      curPage: 1,
      loading: false,
    }
  },
  created: function () {
    // console.log("industry:", this.$route);
    // if (this.$route.params != {} && this.$route.params.category != 'all') {
    //   this.categoryID = this.$route.params.category;
    // } else {
    //   this.categoryID = "1"
    // }
    this.getOrder();
  },
  watch: {
    // $route: {
    //   handler: function (val) {
    //     //console.log(val, oldVal);
    //     if (val.params != {} && val.params.category != 'all') {
    //       this.categoryID = "" + val.params.category;
    //     } else {
    //       this.categoryID = "1"
    //     }
    //     //this.$forceUpdate();
    //     this.reload();
    //   }
    // }
  },
  methods: {
    getOrder() {
      this.items = [];

      let that = this;
      let token = window.localStorage.getItem("token");
      let nextPage = this.curPage;
      if (nextPage <= this.totalPage) {
        this.loading = true;
        this.$http.post(
            '/index/auth/purchase_list',
            {
              // category_id: this.category_id,
              limit: 15,
              page: nextPage
            }, {
              headers: {
                'token': token
              }
            })
            .then(function (response) {
              console.log(response);
              that.loading = false;

              that.totalPage = response.data.last_page;
              that.curPage = response.data.current_page;

              let reData = response.data.data;
              for (let i = 0; i < reData.length; i++) {
                let tmpItem = reData[i];
                that.items.push(
                    {
                      date: tmpItem.pay_at,
                      price: tmpItem.amount,
                      article: tmpItem.article_name,
                      article_id: tmpItem.article_id
                    }
                )
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.curPage = val;
      this.getOrder();
    },
    reload() {
      // 移除组件
      this.update = false
      // 在组件移除后，重新渲染组件
      // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
      this.$nextTick(() => {
        this.update = true
      })
    }
  }
};
</script>
